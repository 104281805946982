import React from 'react';

import CaseStudyElement from './CaseStudyElement';
import CaseStudiesData from './casestudies-data.json';

import '../../styles/theme-text.scss';
import './CaseStudiesList.scss';

const CaseStudiesList = ({ caseStudyTags = [] }) => {
  const caseStudies = caseStudyTags.length
    ? CaseStudiesData.filter((item) => {
        return item.tags.filter((tag) => caseStudyTags.includes(tag)).length > 0;
      })
    : CaseStudiesData;

  return (
    <div className="th-case-studies-list">
      <div className="container-lg">
        <div className="row no-gutters th-case-studies-list__row">
          <div className="col-12">
{/*
            <h2 className="th-theme-content__header th-theme-content__header--decorated">See our previous projects</h2>
*/}
          </div>
        </div>
        <div className="row th-case-studies__row">
          {caseStudies.map((item) => (
            <div key={item.id} className="col-lg-6 th-case-studies__col">
              <CaseStudyElement image={item.image} title={item.title} text={item.text} link={item.link} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CaseStudiesList;
