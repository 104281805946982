import React from 'react';

import SectionBlock from '../components/Block/SectionBlock';
import CaseStudiesList from '../components/CaseStudy/CaseStudiesList';

const sections = [
  {
    img: '/pillars/apps_main.png',
    cta: {
      text: 'Contact us',
      link: '/contact',
    },
    text: [
      {
        title: 'New Apps and Services',
        description:
          '<p>New Apps and Services development supports business innovations and lets you make your ideas live.</p>' +
          '<p>Do you need a quick PoC that is easy to scale-up in the future?</p>' +
          '<p>Do you need web or mobile applications supporting your new business services?</p>' +
          '<p>Does your application require a reliable backend solution?</p>' +
          '<p>SolveQ is committed to help your company stay innovative. With cross-functional teams of developers, ' +
          'we build modern applications that support your business.  Our team delivers full-stack software development ' +
          'services in the field of end-to-end web and mobile application development and backend services. We also ' +
          'implement a full cloud infrastructure including automated CI/CD pipeline. So your features go to the ' +
          'clients constantly.</p>',
      },
    ],
  },
];

const NewAppsAndServicesPage = () => (
  <>
    <div className="th-hero-page">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="th-hero-content">
              <h1 className="th-hero-content__header">New Apps and Services</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <SectionBlock imgFull {...sections[0]} />
    <CaseStudiesList caseStudyTags={['apps']} />
  </>
);

export default NewAppsAndServicesPage;
