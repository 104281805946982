import React from 'react';
import { Link } from 'gatsby';

import './CaseStudyElement.scss';

const CaseStudyElement = ({ title, text, image, link }) => {
  return (
    <div className="th-case-study-box">
      <img src={image} className="th-case-study-box__image" alt="company logo" />
      <div className="row th-case-study-box__row">
        <div className="col-sm-9">
          <div className="col-md-12">
            <h4 className="th-case-study-box__header">{title}</h4>
          </div>
          <div className="col-sm-12 th-case-study-box__content">
            <p className="th-case-study-box__paragraph">{text}</p>
            <div className="text-md-left th-case-study-button">
              <Link to={link}>Project details</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaseStudyElement;
